import classes from './Search.module.css';

const Search: React.FC<{ setDisplayNewOrderForm: React.Dispatch<React.SetStateAction<string>> }> = (props) => {

    return (
        <section className={classes.search}>
            <article className={classes.searchQuery}>
                <h3 className={classes.searchQueryHeading}>0 Order Found</h3>

                <div className={classes.searchQueryItem}>
                    <label>Sort by:</label>
                    <select>
                        <option value="Order ID">Order ID</option>
                        <option value="Date">Date</option>
                        <option value="Name">Name</option>
                        <option value="City">City</option>
                    </select>
                </div>

                <div className={classes.searchQueryItem}>
                    <label>Filter:</label>
                    <select>
                        <option value="All Orders">All Orders</option>
                    </select>
                </div>
            </article>

            <article className={classes.searchOrder}>
                <button className={classes.primary} onClick={() => { props.setDisplayNewOrderForm('block') }}><img src="./images/add-order.png" alt="Add order" /> Add New Order</button>
                <button>More •••</button>
            </article>
            <section>


            </section>

        </section>
    )
}

export default Search