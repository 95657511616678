import React from 'react';
import { useState } from 'react';
import OrderModel from '../models/orderModel';

type OrdersContextTypeObj = {
    orders: OrderModel[];
    addOrder: (order: OrderModel) => void;
    deleteOrder: (id?: string) => void;
}

export const OrdersContext = React.createContext<OrdersContextTypeObj>({
    orders: [],
    addOrder: () => { },
    deleteOrder: (id?: string) => { },
});

const OrdersContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
    const [orders, setOrders] = useState<OrderModel[]>([])

    const addOrderHandler = (order: OrderModel) => {
        const newOrder = order;

        setOrders((prevOrders) => {
            return prevOrders.concat(newOrder);
        })
    }

    const deleteOrderHandler = (orderId?: string) => {
        setOrders((prevOrders) => {
            return prevOrders.filter(order => order.id !== orderId);
        })
    }

    const contextValue: OrdersContextTypeObj = {
        orders: orders,
        addOrder: addOrderHandler,
        deleteOrder: deleteOrderHandler,
    }

    console.log('orders', contextValue.orders)

    return <OrdersContext.Provider value={contextValue}>{props.children}</OrdersContext.Provider>
}

export default OrdersContextProvider;