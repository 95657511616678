import { useRef } from "react"
import OrderModel from "../../models/orderModel";
import classes from "./NewOrderForm.module.css"
import { useContext } from 'react';
import { OrdersContext } from '../../store/orders-context';

const NewOrderForm: React.FC<{ displayNewOrderForm: string; setDisplayNewOrderForm: React.Dispatch<React.SetStateAction<string>> }> = props => {
    const OrdersCtx = useContext(OrdersContext);

    const orderIdInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const cityInputRef = useRef<HTMLInputElement>(null);
    const paymentStatusSelectRef = useRef<HTMLSelectElement>(null);
    const fullfillmentSelectRef = useRef<HTMLSelectElement>(null);
    const totalInputRef = useRef<HTMLInputElement>(null);


    const submitHandler = (event: React.FormEvent) => {
        event.preventDefault();
        const enteredIdInput = orderIdInputRef.current?.value;
        const enteredNameInputRef = nameInputRef.current?.value;
        const enteredCityInputRef = cityInputRef.current?.value;
        const enteredPaymentStatusSelectRef = paymentStatusSelectRef.current?.value;
        const enteredFullfillmentSelectRef = fullfillmentSelectRef.current?.value;
        const enteredTotalInputRef = totalInputRef.current?.value;

        const orderData: OrderModel = {
            id: enteredIdInput,
            date: '',
            name: enteredNameInputRef,
            city: enteredCityInputRef,
            payment: enteredPaymentStatusSelectRef,
            fullfillment: enteredFullfillmentSelectRef,
            total: enteredTotalInputRef,
        }

        // console.log(orderData);

        OrdersCtx.addOrder(orderData);
    }
    return (
        <div id="myNewOrderForm" className={classes.NewOrderForm} style={{ display: props.displayNewOrderForm }} >

            <div className={classes.NewOrderFormContent}>
                <span className={classes.close} onClick={() => { props.setDisplayNewOrderForm('none') }}>&times;</span>
                <h4>Create a new Order</h4>
                <form className={classes.NewOrderFormContentForm} onSubmit={submitHandler}>
                    <input className={classes.NewOrderFormContentFormInput} type="text" placeholder="Order ID" required ref={orderIdInputRef} />
                    <input className={classes.NewOrderFormContentFormInput} type="text" placeholder="Name" ref={nameInputRef} />
                    <input className={classes.NewOrderFormContentFormInput} type="text" placeholder="City" ref={cityInputRef} />

                    <select className={classes.NewOrderFormContentFormSelect} required ref={paymentStatusSelectRef}>
                        <option value="Paid">Paid</option>
                        <option value="Awaiting Authorization">Awaiting Authorization</option>
                    </select>
                    <select className={classes.NewOrderFormContentFormSelect} ref={fullfillmentSelectRef}>
                        <option value="Fulfilled">Fulfilled</option>
                        <option value="Unfulfilled">Unfulfilled</option>
                    </select>

                    <input className={classes.NewOrderFormContentFormInput} type="text" placeholder="Total" ref={totalInputRef} />

                    <input className={classes.NewOrderFormContentFormSelectSubmit} type="submit" value="Create" />
                </form>
            </div>

        </div>
    )
}

export default NewOrderForm