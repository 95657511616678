import classes from './Orders.module.css';
import Order from '../Order/Order';

import { useContext } from 'react';
import { OrdersContext } from '../../store/orders-context';
const Orders: React.FC = () => {

    const OrdersCtx = useContext(OrdersContext);
    return (
        <section className={classes.orders}>

            <article className={classes.ordersTable}>
                <input type="checkbox" disabled className={classes.inputCheckbox} />
                <p className={classes.ordersTableOrderid}>order</p>
                <p className={classes.ordersTableDate}>date</p>
                <p className={classes.ordersTableName}>name</p>
                <p className={classes.ordersTableCity}>city</p>
                <p className={classes.ordersTablePayment}>payment status</p>
                <p className={classes.ordersTableOrder}>order status</p>
                <p className={classes.ordersTableTotal}>total</p>
            </article>
            <section>
                {OrdersCtx.orders.map((order) => (
                    <Order key={order.id} order={order} />
                ))}
            </section>

        </section>

    )
}

export default Orders