import classes from './Navbar.module.css'

const Navbar = () => {
    return (
        <section className={classes.Navbar}>
            <section className={classes.navbarHeading}>
                <h3 className={classes.navbarHeadingText}>Orders</h3>
                <article className={classes.navbarHeadingStatus}>
                    <p>Subscription status:</p>
                    <span>• Valid (Aug 24 2021)</span>
                </article>
            </section>
            <input className={classes.navbarInput} type="text" placeholder="Start typing to search..." />
            <nav className={classes.navbarNavigation}>
                <ul className={classes.navbarNavigationList}>
                    <a href="/"><li><img src="./images/basket.png" alt="" />Orders</li></a>
                    <a href="/"><li><img src="./images/subscription.png" alt="" />Subscription</li></a>
                    <a href="/"><li><img src="./images/member.png" alt="" />Members</li></a>
                    <a href="/"><li><img src="./images/bookmark.png" alt="" />Properties</li></a>
                    <a href="/"><li><img src="./images/council.png" alt="" />Branchs</li></a>
                    <a href="/"><li><img src="./images/reports.png" alt="" />Reports</li></a>
                </ul>
            </nav>
        </section>
    )
}

export default Navbar