import React from 'react';
import classes from './Header.module.css';

const Header = () => {

    return (
        <div className={classes.header}>
            <div className={classes.logo}>NexTecs</div>
            <div className={classes.profile}>
                <img className={classes.profileNotifications} src="./images/notifications.svg" alt="Notifications" />
                <img className={classes.profileImage} src="./images/avatar.png" alt="Profile" />
            </div>
        </div>
    )
}

export default Header