import { useState } from 'react';
import classes from './App.module.css';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import Search from './components/Search/Search';
import Orders from './components/Orders/Orders';
import OrderModel from './models/orderModel';
import NewOrderForm from './components/NewOrderForm/NewOrderForm';
import OrdersContextProvider from './store/orders-context';

function App() {

  const [displayNewOrderForm, setDisplayNewOrderForm] = useState('none');

  return (
    <OrdersContextProvider>
      <div className={classes.wrapper}>
        <div className={classes.whiteBgWrapper}>
          <Header />
          <hr />
          <Navbar />
          <hr />
        </div>
        <div className={classes.greyBgWrapper}>
          <Search setDisplayNewOrderForm={setDisplayNewOrderForm} />
          <Orders />
          <NewOrderForm displayNewOrderForm={displayNewOrderForm} setDisplayNewOrderForm={setDisplayNewOrderForm} />
        </div>
      </div>
    </OrdersContextProvider>
  );
}

export default App;
