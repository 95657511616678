import classes from './Order.module.css';
import { useState } from 'react';
import { useContext } from 'react';
import { OrdersContext } from '../../store/orders-context';
import OrderModel from '../../models/orderModel';

const Order: React.FC<{ order: OrderModel }> = (props) => {

    const [checked, setChecked] = useState(false);
    const OrdersCtx = useContext(OrdersContext);

    return (
        <section>
            {
                <article key={props.order.id}
                    className={classes.ordersContainer} style={{ background: checked ? '#eee' : '#fff', padding: checked ? '0px' : '15px 0px' }}
                    onClick={() => setChecked(!checked)}
                >
                    <input type="checkbox" onChange={() => setChecked(!checked)} className={classes.inputCheckbox} checked={checked} />
                    <p className={classes.orderid}>{props.order.id}</p>
                    <p className={classes.date}>{props.order.date}</p>
                    <p className={classes.name}>{props.order.name}</p>
                    <p className={classes.city}>{props.order.city}</p>
                    <p className={classes.payment}>{props.order.payment}</p>
                    <p className={classes.order}>{props.order.fullfillment}</p>
                    <p className={classes.total}>{props.order.total}</p>
                    <span onClick={() => OrdersCtx.deleteOrder(props.order.id)} style={{ fontSize: '18px', display: checked ? 'block' : 'none' }}>✕
                    </span>
                </article >
            }

        </section>
    )
}

export default Order